import React, {useEffect,useMemo,useState} from 'react';
import {connect} from 'react-redux';

import { Avatar, Box,Button , Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';

import { fileHost } from '../../config';
import { getAsyncBaseCity, getAsyncBaseJob, getAsyncBaseProvidence } from '../../store/actions/layout/BaseAction';
import { getAsyncBizWallet } from '../../store/actions/main/BizAwardAction';
import { eduGradeList, expYearList, posStatusList, posTypeList } from '../../utils/SysData';
import { getAsyncBizPos } from '../../store/actions/main/BizPosListAction';
import SimpleModal from '../layout/SimpleModal';

const commonUtil = require("../../utils/CommonUtil")

const BizPosList = (props) => {    
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const [providenceList, setProvidenceList] = useState([])
    const [cityList, setCityList] = useState([])
    const [jobList, setJobList] = useState([])

    const [bizName, setBizName] = useState("")
    const [bizShortName, setBizShortName] = useState("")
    const [jobId, setJobId] = useState("")
    const [posType, setPosType] = useState("")
    const [expYear, setExpYear] = useState("")
    const [eduLevel, setEduLevel] = useState("")
    const [status, setStatus] = useState("")
    const [keyword, setKeyword] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [providenceId,setProvidenceId] = useState(0)
    const [cityId,setCityId] = useState(0)
    const [createdStart, setCreatedStart] = useState()
    const [createdEnd, setCreatedEnd] = useState()

    const [modelObj, setModelObj] = useState({})
    const [modelArray, setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);  

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
    }

    const query = async(pageNumber) => {
        const queryObj ={
            pageNumber,pageSize,bizName,bizShortName,name,phone,providenceId,cityId,
            status,jobId,posType,expYear,eduLevel,keyword
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncBizPos(queryObj);
        if(res && res.list){
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }
    const reset = ()=>{
        setBizShortName("")
        setBizName("")
        setName("")
        setPhone("")
        setProvidenceId()
        setCityId()
        setCityList([])
        setKeyword("")
        setJobId()
        setPosType()
        setStatus()
        setExpYear()
        setEduLevel()
        setCreatedStart(null)
        setCreatedEnd(null)
    }

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }
    const getBaseJob = async() => {
        const res = await getAsyncBaseJob({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setJobList([...tmpArray])
        }
    }
    useEffect(()=>{
        query(pageNumber);
        getProvidence();
        getBaseJob();
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >职位库</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizShortName}
                                onChange={(e)=>setBizShortName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业负责人"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                         <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="负责人电话"
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >职位分类</InputLabel>
                                <Select label="职位分类"
                                    value={jobId}
                                    onChange={(event, value) => {
                                        setJobId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {jobList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >职位类型</InputLabel>
                                <Select label="职位类型"
                                    value={posType}
                                    onChange={(event) => {
                                        setPosType(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {posTypeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >职位状态</InputLabel>
                                <Select label="职位状态"
                                    value={status}
                                    onChange={(event) => {
                                        setStatus(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {posStatusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                     <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="关键词"
                                value={keyword}
                                onChange={(e)=>setKeyword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >工作经验</InputLabel>
                                <Select label="工作经验"
                                    value={expYear}
                                    onChange={(event) => {
                                        setExpYear(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {expYearList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >学历</InputLabel>
                                <Select label="学历"
                                    value={eduLevel}
                                    onChange={(event) => {
                                        setEduLevel(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {eduGradeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={providenceId}
                                    onChange={(event, value) => {
                                        setProvidenceId(event.target.value);
                                        getCity({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={cityId}
                                    onChange={(event, value) => {
                                        setCityId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">职位ID</TableCell>
                                <TableCell  align="center">企业简称</TableCell>
                                <TableCell  align="center">职位名称</TableCell>
                                <TableCell  align="center">职位分类</TableCell>
                                <TableCell  align="center">薪资待遇</TableCell>
                                <TableCell  align="center">职位类型</TableCell>
                                <TableCell  align="center">工作经验</TableCell>
                                <TableCell  align="center">学历</TableCell>
                                <TableCell  align="center">城市地区</TableCell>
                                <TableCell  align="center">状态</TableCell>
                                <TableCell  align="center">刷新时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                                {/* <TableCell  align="center">操作</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center">{row.bizShortName}</TableCell>
                                    <TableCell align="center">{row.posTitle}</TableCell>
                                    <TableCell align="center">{row.jobName}</TableCell>
                                    <TableCell align="center">{commonUtil.getSalaryText(row.salaryMin,row.salaryMax)}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(posTypeList,row.posType)}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(expYearList,row.expYear)}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(eduGradeList,row.eduLevel)}</TableCell>
                                    <TableCell align="center">{`${row.cityName}-${row.areaName}`}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(posStatusList,row.status)}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.refreshAt)}</TableCell>
                                    
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                  
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,n)=>{changePage(n)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'sm'}
                title={ '职位信息'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    <Paper sx={{padding:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">企业信息</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{padding:1}}>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>
                                    <Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">企业ID:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizId}</Typography> 
                                        </Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">企业名称:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizName}</Typography> 
                                        </Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">企业简称:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizShortName}</Typography> 
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                {modelObj.bizAvatar && modelObj.bizAvatar !='' && 
                                    <Avatar src={"http://"+fileHost+ modelObj.bizAvatar} variant='square' sx={{ width: 96, height: 96}}   />}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{padding:2,width:"100%",marginTop:2}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">职位信息</Typography>
                            </Grid>
                        </Grid>
                        <Grid container  spacing={1} style={{paddingTop:10}}>
                           
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">职位名称:</Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.posTitle}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">职位类型:</Typography>
                                    <Typography gutterBottom variant="overline" component="div">{commonUtil.getJsonValue(posTypeList,modelObj.posType)}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">职位类别: </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.jobName}-{modelObj.jobTypeName}-{modelObj.jobTypeSubName}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">学历要求: </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{commonUtil.getJsonValue(eduGradeList,modelObj.eduLevel)}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">工作年限: </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{commonUtil.getJsonValue(expYearList,modelObj.expYear)}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">薪资待遇:  </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{commonUtil.getSalaryText(modelObj.salaryMin,modelObj.salaryMax)}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">招聘电话: </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.posPhone}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">工作城市: </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.providenceName}-{modelObj.cityName}-{modelObj.areaName}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">详细地址:  </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.address}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack>
                                    <Typography gutterBottom variant="button" component="div">职位描述:   </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.posContent}</Typography> 
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={{padding:2,marginTop:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">岗位负责人</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{padding:1}}>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>
                                    <Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">姓名:  </Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.name}</Typography> 
                                        </Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">电话:  </Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.phone}</Typography> 
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                {modelObj.avatar && modelObj.avatar !='' && 
                                    <Avatar src={"http://"+fileHost+ modelObj.avatar} sx={{ width: 48, height: 48}}   />}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizPosList);
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';


import { Box, Button, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { getSensitiveWordAsync, updateSensitiveWordAsync } from '../../store/actions/main/SensitiveSettingAction';


const sysAlert = require('../../utils/SysAlert');

const SensitiveSetting = (props) => {

    const [word, setWord] = useState('');
    const [validation,setValidation] = useState({});

    const getSensitiveWord = async () => {
        const res = await getSensitiveWordAsync({});
        if(res && res.list){
            setWord(res.list.word)
        }
    }

    const updateSensitiveWord = async() => {
        const res = await updateSensitiveWordAsync({word});
        if(res){
            sysAlert.InfoAlert("操作成功");
        }
    }
    useEffect(()=> {
        getSensitiveWord()
    },[])
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >敏感词设置</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container mt={3}>
                <Grid item xs={12} sx={{marginTop:1}}>
                    <TextField label="敏感词" 
                        fullWidth 
                        variant="outlined"  
                        placeholder='敏感词用|分割'
                        multiline
                        maxRows={10}
                        minRows={5}
                        value={word}
                        onChange={(e) => {
                            setWord(e.target.value);
                        }}
                        error={validation.word&&validation.word!=''}
                        helperText={validation.word}
                    />
                </Grid>
                <Grid item xs={12} sx={{marginTop:1}}>
                    <Button fullWidth variant='contained' onClick={updateSensitiveWord}>保存</Button>
                </Grid>
            </Grid>
        </Box>
    )
}


export default SensitiveSetting;
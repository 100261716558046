import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';

import {Box, Button,Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncBizList } from '../../store/actions/main/BizListAction';
import { forbinAsyncLiveInfo, getAsyncLiveInfo, resumeAsyncLiveInfo } from '../../store/actions/main/LivePlanAction';
import SimpleModal from '../layout/SimpleModal';
import { liveInfoStatusList } from '../../utils/SysData';
import { fileHost } from '../../config';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';

const commonUtil = require("../../utils/CommonUtil")
const LiveChannel = (props) => {
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [planName,setPlanName] = useState("")
    const [status,setStatus] = useState("")
    const [bizShortName,setBizShortName] = useState("")
    const [bizName,setBizName] = useState("")
    
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()
    const [startAtStart,setStartAtStart] = useState()
    const [startAtEnd,setStartAtEnd] = useState()

    const [bizInfo,setBizInfo] = useState({})
    const [modelObj,setModelObj] = useState({})
    const [modelArray,setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);   
    
    const [validation,setValidation] = useState({});

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
        getBizInfo(data.bizId)
    }

    const query = async()=>{
        const queryObj ={
            pageNumber,pageSize,planName,status
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        if(startAtStart != null && startAtStart != ""){
            queryObj.startAtStart = startAtStart+ "T00:00:00Z"
        }
        if(startAtEnd != null && startAtEnd != ""){
            queryObj.startAtEnd = startAtEnd+ "T23:59:59Z"
        }
        const res = await getAsyncLiveInfo(queryObj);
        if(res && res.list){
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }
    const reset = ()=>{
        setPlanName("")
        setStatus("")
        setBizShortName("")
        setBizName("")
        setCreatedStart(null)
        setCreatedEnd(null)
        setStartAtStart(null)
        setStartAtEnd(null)
    }

    const getBizInfo = async(id)=>{
       const res =  await getAsyncBizList({id})
       if(res && res.list && res.list.length>0){
            setBizInfo(res.list[0])
       }
    }
    
    
    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }

    const updateStatus = async(obj) => {
        if(obj.status == 1) {
            const res = await forbinAsyncLiveInfo(obj)
            if(res){
                SuccessAlert("操作成功")  
            }else {
                ErrorAlert("操作失败")
            }
        }else{
            const res = await resumeAsyncLiveInfo(obj)
            if(res){
                SuccessAlert("操作成功")  
            }else {
                ErrorAlert("操作失败")
            }
        }
        query(pageNumber);
    }

    useEffect(()=>{
        query(pageNumber);
    },[])



    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >直播间管理</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>                        
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="计划名称"
                                value={planName}
                                onChange={(e)=>setPlanName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizShortName}
                                onChange={(e)=>setBizShortName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                        
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >频道状态</InputLabel>
                                <Select label="频道状态"
                                    value={status}
                                    onChange={(event, value) => {
                                        setStatus(event.target.value);
                                       
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {liveInfoStatusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="审批开始日期" value={startAtStart} onChange={e=>setStartAtStart(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="审批截止日期"  value={startAtEnd}  onChange={e=>setStartAtEnd(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">计划名称</TableCell>
                                <TableCell  align="center">企业简称</TableCell>
                                <TableCell  align="center">直播频道</TableCell>
                                <TableCell  align="center">申请时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.planName}</TableCell>
                                    <TableCell align="center" >{row.bizShortName}</TableCell>
                                    <TableCell align="center" >{row.channelId}</TableCell>
                                      <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                        <Switch checked={row.status == 1} onChange={()=>{updateStatus(row)}}/>
                                    </TableCell>                                    
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber+1)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'sm'}
                title={ '直播申请'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    <Paper sx={{padding:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">企业信息</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{padding:1}}>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>企业简称: {bizInfo.bizShortName}</Grid>
                                <Grid item>组织结构代码:{bizInfo.bizOrgId}</Grid>
                            </Grid>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>全称: {bizInfo.bizName}</Grid>
                                <Grid item>成立日期: {bizInfo.publishOn}</Grid>
                            </Grid>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>城市: {bizInfo.providenceName}-{bizInfo.cityName}-{bizInfo.areaName}</Grid>
                                <Grid item>地址: {bizInfo.address}</Grid>
                            </Grid>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>姓名: {modelObj.bizUserName}</Grid>
                                <Grid item>电话: {modelObj.bizUserPhone}</Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    
                    <Paper sx={{padding:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">频道参数</Typography>
                            </Grid>
                        </Grid>
                        <Grid container  spacing={1} style={{paddingTop:10}}>
                            <Grid item xs={6}>频道ID: {modelObj.channelId}</Grid>
                            <Grid item xs={6}>拉流编码:{modelObj.pushStreamCode}</Grid>
                            <Grid item xs={6}>推流地址:{modelObj.pushStreamAddr}</Grid>
                            <Grid item xs={6}>拉流地址:{modelObj.streamAddr}</Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={{padding:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">频道数据</Typography>
                            </Grid>
                        </Grid>
                        <Grid container  spacing={1} style={{paddingTop:10}}>
                            <Grid item xs={6} sx={{}}>
                                <img src={modelObj.liveImg != null &&  modelObj.liveImg != "" ? "http://"+fileHost+modelObj.liveImg:'/live_bg.png'} style={{width:"100%",maxHeight:420,aspectRatio:20/9,objectFit:'cover'}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container  spacing={1} style={{paddingTop:10}}>
                                    <Grid item xs={6}>观看人次:{modelObj.userNum}</Grid>
                                    <Grid item xs={6}>点赞人数:{modelObj.likeNum}</Grid>
                                    {/* <Grid item xs={6}>互动次数:{modelObj.talkNum}</Grid>
                                    <Grid item xs={6}>分享次数:{modelObj.shareNum}</Grid> */}
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Paper>
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveChannel);
import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');


export const getAsyncBizPos = async (obj) =>{
    try{
        let url = apiHost + '/public/bizPos?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取企业职位列表信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}
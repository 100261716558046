import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');

export const getAsyncComplainOpts = async (obj) => {
    try{
        let url = apiHost + '/public/complainOpt?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncComplainOptSub = async (obj) =>{
    try{
        let url = apiHost + '/public/complainOptSub?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncLiveUserComplainList = async (obj) =>{
    try{
        let url = apiHost + '/admin/liveUserComplain?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const getAsyncPosComplainList = async (obj) =>{
    try{
        let url = apiHost + '/admin/posComplain?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const getAsyncProfileComplainList = async (obj) =>{
    try{
        let url = apiHost + '/admin/profileComplain?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const updateAsyncLiveComplain = async (params) =>  {
    try {
        let url = apiHost + `/admin/liveUserComplain/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const updateAsyncPosComplain = async (params) =>  {
    try {
        let url = apiHost + `/admin/posComplain/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const updateAsyncProfileComplain = async (params) =>  {
    try {
        let url = apiHost + `/admin/profileComplain/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data;
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null;
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null;
    }
}

export const createAsyncComplianOpt = async(params) => {
    try {
        let url = apiHost + '/admin/complainOpt';
        params.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, params));
        if (res.success) {
            return res.data;
        }else{
            sysAlert.WarningAlert("创建失败")
            return null;
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return null;
    }
}

export const updateAsyncComplainOpt = async (params) =>  {
    try {
        let url = apiHost + `/admin/complainOpt/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data;
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null;
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null;
    }
}


export const createAsyncComplianOptSub = async(params) => {
    try {
        let url = apiHost + '/admin/complainOptSub';
        params.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, params));
        if (res.success) {
            return res.data;
        }else{
            sysAlert.WarningAlert("创建失败")
            return null;
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return null;
    }
}

export const updateAsyncComplainOptSub = async (params) =>  {
    try {
        let url = apiHost + `/admin/complainOptSub/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data;
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null;
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null;
    }
}
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import { Box, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncBaseCity, getAsyncBaseProvidence } from '../../store/actions/layout/BaseAction';
import { getAsyncBizWallet } from '../../store/actions/main/BizAwardAction';
const commonUtil = require("../../utils/CommonUtil")

const BizAward = (props) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const [providenceList, setProvidenceList] = useState([])
    const [cityList, setCityList] = useState([])

    const [bizName, setBizName] = useState("")
    const [bizShortName, setBizShortName] = useState("")
    const [bizUserName, setBizUserName] = useState("")
    const [bizUserPhone, setBizUserPhone] = useState("")
    const [providenceId,setProvidenceId] = useState(0)
    const [cityId,setCityId] = useState(0)
    const [createdStart, setCreatedStart] = useState()
    const [createdEnd, setCreatedEnd] = useState()
    const [startAtStart, setStartAtStart] = useState()
    const [startAtEnd, setStartAtEnd] = useState()

    const [modelObj, setModelObj] = useState({})
    const [modelArray, setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);  

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
    }


    const query = async(pageNumber) => {
        const queryObj ={
            pageNumber,pageSize,bizName,bizShortName,bizUserName,bizUserPhone,providenceId,cityId
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        if(startAtStart != null && startAtStart != ""){
            queryObj.startAtStart = startAtStart+ "T00:00:00Z"
        }
        if(startAtEnd != null && startAtEnd != ""){
            queryObj.startAtEnd = startAtEnd+ "T23:59:59Z"
        }
        const res = await getAsyncBizWallet(queryObj);
        if(res && res.list){
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }
    const reset = ()=>{
        setBizShortName("")
        setBizName("")
        setBizUserName("")
        setBizUserPhone("")
        setProvidenceId()
        setCityId()
        setCityList([])
        setCreatedStart(null)
        setCreatedEnd(null)
    }

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }

    useEffect(()=>{
        query(pageNumber);
        getProvidence();
    },[])
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >企业权益</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizShortName}
                                onChange={(e)=>setBizShortName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业负责人"
                                value={bizUserName}
                                onChange={(e)=>setBizUserName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                         <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="负责人电话"
                                value={bizUserPhone}
                                onChange={(e)=>setBizUserPhone(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                    <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={providenceId}
                                    onChange={(event, value) => {
                                        setProvidenceId(event.target.value);
                                        getCity({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={cityId}
                                    onChange={(event, value) => {
                                        setCityId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="申请开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="申请截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">企业ID</TableCell>
                                <TableCell  align="center">企业名称</TableCell>
                                <TableCell  align="center">企业简称</TableCell>
                                <TableCell  align="center">企业负责人</TableCell>
                                <TableCell  align="center">负责人电话</TableCell>
                                <TableCell  align="center">信息栏</TableCell>
                                <TableCell  align="center">职位刷新</TableCell>
                                <TableCell  align="center">招聘会</TableCell>
                                <TableCell  align="center">直播刷新</TableCell>
                                <TableCell  align="center">简历解锁</TableCell>
                                {/* <TableCell  align="center">操作</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.bizId}</TableCell>
                                    <TableCell align="center">{row.bizName}</TableCell>
                                    <TableCell align="center">{row.bizShortName}</TableCell>
                                    <TableCell align="center">{row.bizUserName}</TableCell>
                                    <TableCell align="center">{row.bizUserPhone}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.expiredAt)}</TableCell>
                                    <TableCell align="center">{row.refreshCount}</TableCell>
                                    <TableCell align="center">{row.liveCount}</TableCell>
                                    <TableCell align="center">{row.liveRefreshCount}</TableCell>
                                    <TableCell align="center">{row.profileCount}</TableCell>
                                    
                                    {/* <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>  */}                                   
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,n)=>{changePage(n)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizAward);
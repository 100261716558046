import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import { Box, Button, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncBizLiveUpload, getAsyncVodList } from '../../store/actions/main/LivePlanAction';
import SimpleModal from '../layout/SimpleModal';
const commonUtil = require("../../utils/CommonUtil")

const LiveUpload = (props) => {
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [bizShortName,setBizShortName] = useState("");
    const [bizName,setBizName] = useState("");
    const [vodList, setVodList] = useState([]);
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const [modelObj,setModelObj] = useState({});
    const [modelArray,setModelArray] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);  

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
    }

    const query = async(page) => {
        const queryObj ={
            pageNumber:page,pageSize,bizShortName,bizName
        }
        
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncBizLiveUpload(queryObj);
        if(res && res.list){
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }

    const reset = ()=>{
        setStatus("")
        setBizShortName("")
        setBizName("")
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    
    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }

    const getVodList = async(paramObj) => {
        const vodRes = await getAsyncVodList(paramObj);
        if(vodRes && vodRes.list){
            setVodList(vodRes.list.list)
        }
    }

    useEffect(()=>{
        query(pageNumber);
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >企业上传</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}> 
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizShortName}
                                onChange={(e)=>setBizShortName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="上传开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="上传截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">企业简称</TableCell>
                                <TableCell  align="center">企业用户</TableCell>
                                <TableCell  align="center">电话</TableCell>
                                <TableCell  align="center">文件名</TableCell>
                                <TableCell  align="center">文件大小</TableCell>
                                <TableCell  align="center">文件块</TableCell>
                                <TableCell  align="center">视频ID</TableCell>
                                <TableCell  align="center">创建时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.bizShortName}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.phone}</TableCell>
                                    <TableCell align="center">{row.fileName}</TableCell>
                                    <TableCell align="center">{row.fileSize}</TableCell>
                                    <TableCell align="center">{row.total_block}</TableCell>
                                    <TableCell align="center">{row.vid}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row);getVodList({vid:row.vid})}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                    
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'xl'}
                title={'企业上传视频'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                     <Grid item sm={12}><Typography color="primary">ID: {modelObj.id}</Typography></Grid>
                     <Grid item sm={12}><Typography color="primary">企业名称: {modelObj.bizName}</Typography></Grid>
                     <Grid item sm={6}><Typography color="primary">上传用户: {modelObj.name}</Typography></Grid>
                     <Grid item sm={6}><Typography color="primary">上传时间: {commonUtil.getDateTime(modelObj.CreatedAt)}</Typography></Grid>
                     {vodList && vodList.length>0 && 
                        <Grid item sm={12}><video style={{width:"100%"}} controls src={vodList[0].location} /></Grid>
                     }
                     
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveUpload);
import {Frame,Home,Login,NotFound,Setting,AppSetting,
    BizApply,BizAward,BizList,BizListDetail,BizPos,BizPosList,BizStat,
    CmsContentList,CmsTypeList,ComplainLive,ComplainOptSetting, ComplainPos,ComplainProfile,Dashboard,
    LiveApply,LiveChannel,LivePlan,LiveRecord,LiveUpload,NotifySysList,NotifyUserList ,OrderList,PaymentList,ProdList,
    SensitiveSetting,SysAuth,SysInfo,SysUser,Upload,UserDetail,UserList,UserStat, NotifyBizList} from '../pages'


export const routes = [
    // 登录画面
    {
        path: "/",
        key: "index",
        component: <Frame/>,
        children: [
            {
                path: "/", key: "home", component: <Home />
            },
            {
                path: "/biz_apply", key: "biz_apply", component: <BizApply />
            },
            {
                path: "/biz_award", key: "biz_award", component: <BizAward />
            },
            {
                path: "/biz_list", key: "biz_list", component: <BizList />
            },
            {
                path: "/biz_list/:bizId", key: "biz_detail", component: <BizListDetail />
            },
            {
                path: "/biz_pos", key: "biz_pos", component: <BizPos />
            },
            {
                path: "/biz_pos_list", key: "biz_pos", component: <BizPosList />
            },
            {
                path: "/cms_content", key: "cms_content", component: <CmsContentList />
            },
            {
                path: "/cms_type", key: "cms_type", component: <CmsTypeList />
            },
            {
                path: "/complain_live", key: "complain_live", component: <ComplainLive />
            },
            {
                path: "/complain_opt_setting", key: "complain_opt_setting", component: <ComplainOptSetting />
            },
            {
                path: "/complain_pos", key: "complain_pos", component: <ComplainPos />
            },
            {
                path: "/complain_profile", key: "complain_profile", component: <ComplainProfile />
            },
            {
                path: "/live_apply", key: "live_apply", component: <LiveApply />
            },
            {
                path: "/live_channel", key: "live_channel", component: <LiveChannel />
            },
            {
                path: "/live_plan", key: "live_plan", component: <LivePlan />
            },
            {
                path: "/live_record", key: "live_record", component: <LiveRecord />
            },
            {
                path: "/live_upload", key: "live_record", component: <LiveUpload />
            },
            {
                path: "/notify_biz", key: "notify_Biz", component: <NotifyBizList />
            },
            {
                path: "/notify_sys", key: "notify_sys", component: <NotifySysList />
            },
            {
                path: "/notify_user", key: "notify_user", component: <NotifyUserList />
            },
            {
                path: "/order_list", key: "order_list", component: <OrderList />
            },
            {
                path: "/payment_list", key: "payment_list", component: <PaymentList />
            },
            {
                path: "/prod_list", key: "prod_list", component: <ProdList />
            },
            {
                path: "/setting", key: "setting", component: <Setting />
            },
            {
                path: "/dashboard", key: "dashboard", component: <Dashboard />
            },
            {
                path: "/app_setting", key: "app_setting", component: <AppSetting />
            },
            {
                path: "/sensitive_setting", key: "sensitive_setting", component: <SensitiveSetting />
            },
            {
                path: "/sys_auth", key: "sys_auth", component: <SysAuth />
            },
            {
                path: "/sys_info", key: "sys_info", component: <SysInfo />
            },
            {
                path: "/sys_user", key: "sys_user", component: <SysUser />
            },
            {
                path: "/upload", key: "upload", component: <Upload />
            },
            {
                path: "/user_list", key: "user_list", component: <UserList />
            },
            {
                path: "/user_list/:userId", key: "user_detail", component: <UserDetail />
            },
            {
                path: "/stat_user", key: "stat_user", component: <UserStat />
            },
            {
                path: "/stat_biz", key: "stat_biz", component: <BizStat />
            },
            
        ]
    },
    {path: "/login",key:"login", component: <Login/>},
    {path: "/*",key:"404", component: <NotFound/>}
]

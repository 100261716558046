import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import { Box, Button, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncLiveRecord } from '../../store/actions/main/LiveRecordAction';
import { getAsyncBaseCity, getAsyncBaseProvidence } from '../../store/actions/layout/BaseAction';
import { getAsyncLiveInfo } from '../../store/actions/main/LivePlanAction';
import { LIVE_TYPE } from '../../utils/SysConst';
import SimpleModal from '../layout/SimpleModal';
import { fileHost } from '../../config';

const commonUtil = require("../../utils/CommonUtil")

const LiveRecord = (props) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const [providenceList, setProvidenceList] = useState([])
    const [cityList, setCityList] = useState([])

    const [bizName, setBizName] = useState("")
    const [planName, setPlanName] = useState("")
    const [planProvidenceId, setPlanProvidenceId] = useState(0)
    const [planCityId, setPlanCityId] = useState(0)
    const [createdStart, setCreatedStart] = useState()
    const [createdEnd, setCreatedEnd] = useState()
    const [startAtStart, setStartAtStart] = useState()
    const [startAtEnd, setStartAtEnd] = useState()

    const [modelObj, setModelObj] = useState({})
    const [modelArray, setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);  

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
    }


    const query = async(pageNumber)=>{
        const queryObj ={
            status:3,
            pageNumber,pageSize,planName,planCityId,planProvidenceId,
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        if(startAtStart != null && startAtStart != ""){
            queryObj.startAtStart = startAtStart+ "T00:00:00Z"
        }
        if(startAtEnd != null && startAtEnd != ""){
            queryObj.startAtEnd = startAtEnd+ "T23:59:59Z"
        }
        const res = await getAsyncLiveInfo(queryObj);
        if(res && res.list){
            console.log(res)
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }
    const reset = ()=>{
        setPlanName("")
        setBizName("")
        setPlanProvidenceId();
        setPlanCityId();
        setCityList([])
    }

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }

    useEffect(()=>{
        query(pageNumber);
        getProvidence();
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >直播历史</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>   
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={planProvidenceId}
                                    onChange={(event, value) => {
                                        setPlanProvidenceId(event.target.value);
                                        getCity({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={planCityId}
                                    onChange={(event, value) => {                                        
                                        setPlanCityId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="计划名称"
                                value={planName}
                                onChange={(e)=>setPlanName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业名称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd} onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="计划开始日期" value={startAtStart} onChange={e=>setStartAtStart(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="计划截止日期"  value={startAtEnd}  onChange={e=>setStartAtEnd(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">计划名称</TableCell>
                                <TableCell  align="center">直播城市</TableCell>
                                <TableCell  align="center">企业名称</TableCell>
                                <TableCell  align="center">直播类型</TableCell>
                                <TableCell  align="center">职位数</TableCell>
                                <TableCell  align="center">简历数</TableCell>
                                <TableCell  align="center">点赞数</TableCell>
                                <TableCell  align="center">围观数</TableCell>
                                <TableCell  align="center">计划开始时间</TableCell>
                                <TableCell  align="center">计划结束时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.planName}</TableCell>
                                    <TableCell align="center">{row.planCityName}</TableCell>
                                    <TableCell align="center">{row.bizName}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(LIVE_TYPE,row.liveType)}</TableCell>
                                    <TableCell align="center">{row.posNum}</TableCell>
                                    <TableCell align="center">{row.profileNum}</TableCell>
                                    <TableCell align="center">{row.likeNum}</TableCell>
                                    <TableCell align="center">{row.userNum}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.startAt)}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.endAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                    
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,n)=>{console.log(e,n);changePage(n)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'sm'}
                title={ '直播历史'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    <Paper sx={{padding:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">企业信息</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{padding:1}}>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>企业名称: {modelObj.bizName}</Grid>
                            </Grid>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>姓名: {modelObj.bizUserName}</Grid>
                                <Grid item>电话: {modelObj.bizUserPhone}</Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{padding:2,width:"100%",marginTop:2}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">频道数据</Typography>
                            </Grid>
                        </Grid>
                        <Grid container  spacing={1} style={{paddingTop:10}}>
                            <Grid item xs={6} sx={{}}>
                                <img src={modelObj.liveImg != null &&  modelObj.liveImg != "" ? "http://"+fileHost+modelObj.liveImg:'/live_bg.png'} style={{width:"100%",maxHeight:420,aspectRatio:20/9,objectFit:'cover'}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container  spacing={1} style={{paddingTop:10}}>
                                    <Grid item xs={6}>职位数量:{modelObj.posNum}</Grid>
                                    <Grid item xs={6}>简历数量:{modelObj.profileNum}</Grid>
                                    <Grid item xs={6}>观看人次:{modelObj.userNum}</Grid>
                                    <Grid item xs={6}>点赞人数:{modelObj.likeNum}</Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Paper>
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveRecord);
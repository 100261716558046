import React, {useEffect,useMemo,useState} from 'react';
import {connect} from 'react-redux';

import { Box,Chip, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';


const BizPos = (props) => {
    const [propSelect,setPropSelect] = useState({})
    const [propsArray,setPropsArray] = useState([
        {"propName":"季节","propValue":["春季专场应届毕业生","夏季","秋季","冬季"]},
        {"propName":"场次","propValue":["3场","5场","10场"]},
        {"propName":"会员","propValue":["VIP","深度合作"]},
    ])
    const [skuArray, setSkuArray] = useState([
        {propContent:'春季专场应届毕业生|3场|VIP',skuName:'',originPrice:10.00,acutualPrice:0.01},
        {propContent:'春季专场应届毕业生|5场|VIP',skuName:'',originPrice:11.00,acutualPrice:0.11},
        {propContent:'夏季|5场|VIP',skuName:'',originPrice:12.00,acutualPrice:0.12},
        {propContent:'夏季|10场|深度合作',skuName:'',originPrice:13.00,acutualPrice:0.13},
        {propContent:'冬季|3场|深度合作',skuName:'',originPrice:14.00,acutualPrice:0.14},
    ])
    const [selectSku, setSelectSku] = useState({});
    const [remainSkuArray,setRemainSkuArray] = useState([])
    const [remainSkuStr,setRemainSkuStr] = useState('')

    const selectProps = (key,val)=> {
        if(propSelect[key]!=val){
            propSelect[key]=val;
            setPropSelect({...propSelect})
        }else {
            delete propSelect[key]
            setPropSelect({...propSelect})
            if(Object.keys(propSelect)==0){
                setRemainSkuArray([])
            }
        }
    }

    useEffect(()=>{
        let remainSkuTmpStr = ''
        skuArray.map((item)=> {remainSkuTmpStr += item.propContent})
        setRemainSkuStr(remainSkuTmpStr)
    },[])
    useEffect(()=>{
        let tmpSkuArray = []
        const propSelectValArr = [];
        if(Object.keys(propSelect).length >0) {
            for(let k in propSelect){
                if(tmpSkuArray.length ==0){
                    tmpSkuArray = skuArray.filter(item=>{if(item.propContent.indexOf(propSelect[k])>=0) return true})
                }else{
                    tmpSkuArray = tmpSkuArray.filter(item=>{if(item.propContent.indexOf(propSelect[k])>=0) return true})
                }
                propSelectValArr.push(propSelect[k]);
            }
            if(Object.keys(propSelect).length == propsArray.length){
                const selectSkuStr = propSelectValArr.join('|');
                skuArray.filter(item=>{if(item.propContent == selectSkuStr) setSelectSku(item)})
            }
            
            setRemainSkuArray(tmpSkuArray)
            let remainSkuTmpStr = ''
            tmpSkuArray.map((item)=> {remainSkuTmpStr += item.propContent})
            setRemainSkuStr(remainSkuTmpStr)
        }else {
            let remainSkuTmpStr = ''
            skuArray.map((item)=> {remainSkuTmpStr += item.propContent})
            setRemainSkuStr(remainSkuTmpStr)
        }
        
      },[propSelect])
    
    useMemo(()=>{

    })
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >职位库</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={12}> <Grid item xs={6}>请招聘会信息:</Grid></Grid>
                {propsArray && propsArray.length >0 && propsArray.map((item,index)=>{
                    return (
                        <Grid item md={12} key={'prop_name_'+index}>
                            <Grid container spacing={1} style={{paddingTop:10}}>
                                <Grid item display={'flex'}  alignItems="center">
                                    <Typography variant="button" display="block" gutterBottom>
                                    {item.propName}:
                                    </Typography>
                                </Grid>
                                {item.propValue.map((subItem,subIndex)=>{
                                    return (
                                        <Grid item  key={index+"_sub_item"+subIndex} >
                                            <Chip label={subItem}
                                            variant={"outlined"}
                                             style={{borderRadius:0,borderWidth:'medium',borderColor:propSelect[index]==subItem?"#1976d2":"#ccc"}} 
                                             disabled={remainSkuStr.indexOf(subItem)<0}
                                             onClick={()=>{remainSkuStr.indexOf(subItem)>=0?selectProps(index,subItem):undefined}} 
                                             />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid item md={12}> <Grid item xs={6}>可选择: {remainSkuStr}</Grid></Grid>
                {Object.keys(propSelect).length == propsArray.length && <Grid item md={12}> <Grid item xs={6}>已选择: {selectSku.propContent}</Grid> <Grid item xs={6}>价格: {selectSku.acutualPrice}</Grid></Grid>}
            </Grid>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizPos);
/* eslint-disable eqeqeq */
import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
import {SysUserActionType} from '../../types';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');


export const getAsyncLivePlan = async (obj) =>{
    try{
        let url = apiHost + '/public/livePlan?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const createLivePlanAsync = async(appObj) =>  {
    try {
        let url = apiHost + '/admin/livePlan';
        appObj.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, appObj));
        if(res.success){
            return res.data
        }else{
            return null
        }    
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const updateLivePlanAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/livePlan/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const getAsyncLiveApply = async (obj) =>{
    try{
        let url = apiHost + '/admin/liveApply?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const updateLiveApplyAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/livePlan/${params.planId}/liveApply/${params.id}/status/${params.status}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const getAsyncLiveInfo = async (obj) =>{
    try{
        let url = apiHost + '/admin/live?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const forbinAsyncLiveInfo = async (params) => {
    try {
        let url = apiHost + `/admin/biz/${params.bizId}/live/${params.id}/channel/${params.channelId}/forbid`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const resumeAsyncLiveInfo = async (params) => {
    try {
        let url = apiHost + `/admin/biz/${params.bizId}/live/${params.id}/channel/${params.channelId}/resume`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}



export const getAsyncBizLiveUpload = async (obj) =>{
    try{
        let url = apiHost + '/admin/liveUpload?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const getAsyncVodList = async (obj) =>{
    try{
        let url = apiHost + '/admin/vodList?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}
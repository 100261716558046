import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');


export const getSensitiveWordAsync = async (obj) =>{
    try{
        let url = apiHost + '/public/sensitiveWord?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const updateSensitiveWordAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/sensitiveWord`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}
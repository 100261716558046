import React, {useEffect,useRef,useState} from 'react';
import {connect} from 'react-redux';

import { Box, Button,Divider,FormControl,FormHelperText,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getAsyncBaseCity, getAsyncBaseProvidence } from '../../store/actions/layout/BaseAction';
import { createLivePlanAsync, getAsyncLivePlan, updateLivePlanAsync } from '../../store/actions/main/LivePlanAction';
import { updateImageAsync } from '../../store/actions/layout/AppAction';
import SimpleModal from '../layout/SimpleModal';
import { fileHost } from '../../config';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';

const commonUtil = require("../../utils/CommonUtil")

const LivePlan = (props) => {

    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [providenceList,setProvidenceList] = useState([])
    const [cityList,setCityList] = useState([])
    const [modalCityList,setModalCityList] = useState([])

    const [planName,setPlanName] = useState("")
    const [planProvidenceId,setPlanProvidenceId] = useState(0)
    const [planCityId,setPlanCityId] = useState(0)
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()
    const [startAtStart,setStartAtStart] = useState()
    const [startAtEnd,setStartAtEnd] = useState()

    const [modelObj,setModelObj] = useState({})
    const [modelArray,setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);   
    const [newModal, setNewModal] = useState(true);

    const [validation,setValidation] = useState({});

    const fileInputRef = useRef();

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        console.log(data)
        setModalOpen(true)
        if(data == null){
            setNewModal(true)
            setModelObj({})
        }else{
            setNewModal(false)
            setModelObj(data)
            console.log(data.planUrl)
            if(data.planProvidenceId){
                getCityModal({pid:data.planProvidenceId})
            }
        }
    }

    const validate = ()=>{
        const validateObj ={};
        console.log(modelObj)
        if (!modelObj.planName || modelObj.planName.length==0) {
            validateObj.planName ='请输入直播计划名字';
        }
        if (!modelObj.planUrl || modelObj.planUrl.length==0) {
            validateObj.planUrl ='请上传直播计划封面';
        }
        if (!modelObj.planProvidenceId ) {
            validateObj.planProvidenceId ='请选择直播计划省份';
        }
        if (!modelObj.planCityId ) {
            validateObj.planCityId ='请选择直播计划城市';
        }
        if (!modelObj.startAt ) {
            validateObj.startAt ='请选择直播计划开始时间';
        }
        if (!modelObj.endAt ) {
            validateObj.endAt ='请选择直播计划结束时间';
        }
        if (!modelObj.applyEnd ) {
            validateObj.applyEnd ='请选择直播计划申请截止时间';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };


    const query = async()=>{
        const queryObj ={
            pageNumber,pageSize,planName,planCityId,planProvidenceId,
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        if(startAtStart != null && startAtStart != ""){
            queryObj.startAtStart = startAtStart+ "T00:00:00Z"
        }
        if(startAtEnd != null && startAtEnd != ""){
            queryObj.startAtEnd = startAtEnd+ "T23:59:59Z"
        }
        const res = await getAsyncLivePlan(queryObj);
        if(res && res.list){
            console.log(res)
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }
    const reset = ()=>{
        setPlanName("")
    }
    const save = () => {
        const errorCount = validate();
       
        if(errorCount===0){
            setModalOpen(false);
            modelObj.startAt = new Date(modelObj.startAt).toISOString()
            modelObj.endAt = new Date(modelObj.endAt).toISOString()
            modelObj.applyEnd = new Date(modelObj.applyEnd).toISOString()
            newModal?createModel():updateModel()
        }
    }
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }
    const getCityModal = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setModalCityList([...tmpArray])
            }
        }
    }
    const onSelectFile = async(e) => {
        if (e.target.files && e.target.files.length > 0) {
          const fd = new FormData()
          fd.append('file', e.target.files[0])
          const res = await updateImageAsync({fd})
          setModelObj({...modelObj,planUrl:[res]})
        }else{
            ErrorAlert("文件选择错误")
        }
    }
    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }

    const createModel = async()=>{
        
        const res = await createLivePlanAsync(modelObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)
        }else {
            ErrorAlert("操作失败")
        }
    }
    const updateModel = async()=>{
        const res = await updateLivePlanAsync(modelObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    useEffect(()=>{
        query(pageNumber);
        getProvidence();
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >直播计划</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>                        
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="计划名称"
                                value={planName}
                                onChange={(e)=>setPlanName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={planProvidenceId}
                                    onChange={(event, value) => {
                                        setPlanProvidenceId(event.target.value);
                                        getCity({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={planCityId}
                                    onChange={(event, value) => {                                        
                                        setPlanCityId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd} onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="计划开始日期" value={startAtStart} onChange={e=>setStartAtStart(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="计划截止日期"  value={startAtEnd}  onChange={e=>setStartAtEnd(e.format('YYYY-MM-DD'))}  sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="新增">
                        <IconButton color="primary" aria-label="add" onClick={() => {openModal()}} >
                            <Icon path={md["mdiPlusCircle"]} size={1.5}/>
                        </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">计划名称</TableCell>
                                <TableCell  align="center">直播城市</TableCell>
                                <TableCell  align="center">企业数量</TableCell>
                                <TableCell  align="center">计划开始时间</TableCell>
                                <TableCell  align="center">计划结束时间</TableCell>
                                <TableCell  align="center">申请截止时间</TableCell>
                                <TableCell  align="center">创建时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.planName}</TableCell>
                                    <TableCell align="center">{row.planCityName}</TableCell>
                                    <TableCell align="center">{row.bizCount}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.startAt)}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.endAt)}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.applyEnd)}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                    
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'md'}
                title={ newModal ? '新增直播计划' : '修改直播计划'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    {!newModal && <Grid item sm={12}><Typography color="primary">ID：{modelObj.id}</Typography></Grid>}
                    
                    <Grid item xs={12}>
                        <TextField label="标题" fullWidth margin="dense" variant="outlined" value={modelObj.planName}
                                   onChange={(e) => {
                                        setModelObj({...modelObj,planName:e.target.value});
                                   }}
                                   error={validation.planName&&validation.planName!=''}
                                   helperText={validation.planName}
                        />
                    </Grid>
                    <Grid item  xs={6}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={modelObj.planProvidenceId}
                                    onChange={(e, value) => {                                        
                                        setModelObj({...modelObj,planProvidenceId:e.target.value,planProvidenceName:value.props.children});
                                        getCityModal({pid:e.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                                {(validation.planProvidenceId&&validation.planProvidenceId!='') && <FormHelperText style={{color: 'red'}}>{validation.planProvidenceId}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={modelObj.planCityId}
                                    onChange={(e, value) => {
                                        
                                        setModelObj({...modelObj,planCityId:e.target.value,planCityName:value.props.children});
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {modalCityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                                {(validation.planCityId&&validation.planCityId!='') && <FormHelperText style={{color: 'red'}}>{validation.planCityId}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    <Grid item xs={4} sx={{mt:1}}>                            
                        <DateTimePicker label="计划开始时间" value={dayjs(modelObj.startAt)} 
                       
                        onChange={e=>setModelObj({...modelObj,startAt:e.format('YYYY-MM-DD HH:mm:00')})}  
                        sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD HH:mm:00" />
                        {(validation.startAt&&validation.startAt!='') && <FormHelperText style={{color: 'red'}}>{validation.startAt}</FormHelperText>}
                    </Grid>
                    <Grid item xs={4} sx={{mt:1}}>                            
                        <DateTimePicker label="计划结束时间" value={dayjs(modelObj.endAt)}  minDateTime={dayjs(modelObj.startAt)} 
                        onChange={e=>setModelObj({...modelObj,endAt:e.format('YYYY-MM-DD HH:mm:00')})}  
                        sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD HH:mm:00" />
                        {(validation.endAt&&validation.endAt!='') && <FormHelperText style={{color: 'red'}}>{validation.endAt}</FormHelperText>}
                    </Grid>
                    <Grid item xs={4} sx={{mt:1}}>                            
                        <DateTimePicker label="申报截止时间" value={dayjs(modelObj.applyEnd)} maxDateTime={dayjs(modelObj.startAt)} 
                        onChange={e=>setModelObj({...modelObj,applyEnd:e.format('YYYY-MM-DD HH:mm:00')})}  
                        sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD HH:mm:00" />
                        {(validation.applyEnd&&validation.applyEnd!='') && <FormHelperText style={{color: 'red'}}>{validation.applyEnd}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>                           
                            <Box sx={{borderWidth:2,borderStyle: "groove",borderColor: "#f2f2f2",
                            borderRadius: 1,width:"100%",marginTop:"8px",minHeight:"200px"}}>
                                <Stack direction={"column"} sx={{padding:2}}>
                                    <Button component="label" variant="contained" 
                                        onClick={()=>{fileInputRef.current.click()}}
                                        startIcon={<Icon path={md["mdiCloudUploadOutline"]} size={1.5}/>}>
                                        封面上传
                                    </Button>
                                    <input type="file" style={{display:"none"}} ref={fileInputRef} accept="image/*" onChange={onSelectFile} />
                                    <Stack direction={"row"} sx={{marginTop:2}}>
                                    {modelObj.planUrl && modelObj.planUrl.map((item)=>{
                                            return (<div key={"_cms_img_"+item}>
                                                <img style={{objectFit: "cover", maxHeight: 120}} src={"http://"+fileHost+item} ></img>
                                            </div>)
                                    })}
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                </Grid>

            </SimpleModal>
        </Box>
    )
}


export default LivePlan;
import React, {useState,useEffect} from 'react';
import {connect,useDispatch} from 'react-redux';
import {Autocomplete, Box, Button,Checkbox,  Divider,FormControl,FormControlLabel,Grid,IconButton,InputLabel,MenuItem,Paper,Pagination,Select,
    Tab,Tabs,TableContainer, Table, TableHead, TableRow, TableCell, TableBody,TextField,Typography} from '@mui/material';
import { useConfirm } from "material-ui-confirm";
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { SysAuthActionType} from '../../store/types';
import {getSysUserTypeList} from '../../store/actions/main/SysUserAction'
import {changeMenuList, createAdminType,deleteAdminType,updateAdminType} from '../../store/actions/main/SysAuthAction'
import SimpleModal from '../layout/SimpleModal';
import { complainTypeList } from '../../utils/SysData';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';
import { createAsyncComplianOpt, createAsyncComplianOptSub, getAsyncComplainOpts, getAsyncComplainOptSub, updateAsyncComplainOpt } from '../../store/actions/main/ComplainAction';
const sysConst = require('../../utils/SysConst')
const commonUtil = require('../../utils/CommonUtil')

const ComplainOptSetting = (props) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const [tabIndex,setTabIndex] =useState(1);
    const [type, setType] = useState();
    const [complainOptList, setComplainOptList] = useState([]);
    const [complainOptId, setComplainOptId] = useState("")
    const [complainOptSubList, setComplainOptSubList] = useState([]);

    const [newModal, setNewModal] = useState(true);
    const [modelObj, setModelObj] = useState({})
    const [modelArray, setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);  
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [subModelObj,setSubModelObj] = useState({});
    const [validation,setValidation] = useState({});  


    const closeModal = () => {
        setModalOpen(false);
        setTabIndex(1);
    };
    const closeNewModal = () => {
        setNewModalOpen(false);
    };
    const openNewModal = () => {
        setNewModalOpen(true)
        setModelObj({})
    }
    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
        setSubModelObj({pid:data.id})
        queryComplainSubOpt({pid:data.id})
    }

    const validate = ()=>{
        const validateObj ={};
        if (!modelObj.id) {
            validateObj.id ='请输入投诉的ID';
        }
        if (!modelObj.type) {
            validateObj.type ='请选择投诉的类型';
        }
        if (!modelObj.title) {
            validateObj.title ='请输入投诉的标题';
        }
        if (!modelObj.content) {
            validateObj.content ='请输入投诉的提示';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };

    const queryComplainOpt = async (type) => {
        const res = await getAsyncComplainOpts({type})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].title,value:res.list[i].id})
            }
            setComplainOptList([...tmpArray])
            setModelArray(res.list)
            setTotal(res.total)
        }
    }
    const queryComplainSubOpt = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncComplainOptSub(obj)
            if(res && res.list){
                
                setComplainOptSubList(res.list)
            }
        }
    }

    const createComplianOpt = async()=>{
        const errorCount = validate();
        if(errorCount===0){
            modelObj.id = Number.parseInt(modelObj.id);
            const res = await createAsyncComplianOpt(modelObj);
            if(res){
                SuccessAlert("操作成功");
                queryComplainOpt();
            }else {
                ErrorAlert("操作失败");
            }
            setNewModalOpen(false);
        }
    }
    const updateComplianOpt = async()=>{
        const errorCount = validate();
        if(errorCount===0){
            const res = await updateAsyncComplainOpt(modelObj)
            if(res){
                SuccessAlert("操作成功");
                queryComplainOpt();
                
            }else {
                ErrorAlert("操作失败");
            }
            setModalOpen(false);
        }
    }
    const createComplianOptSub = async()=>{
        
            subModelObj.id = Number.parseInt(subModelObj.id);
            const res = await createAsyncComplianOptSub(subModelObj);
            if(res){
                SuccessAlert("操作成功");
                queryComplainOpt();
            }else {
                ErrorAlert("操作失败");
            }
            setModalOpen(false);
    }
    const updateComplianOptSub = async()=> {

    }
    return (
        <Box style={{paddingTop:10}}>
            <Typography gutterBottom variant="h6" >投诉设置</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel >投诉类型</InputLabel>
                        <Select label="投诉类型"
                            value={type}
                            onChange={(event) => {
                                setType(event.target.value);
                                queryComplainOpt(event.target.value)
                            }}>
                            <MenuItem value="">请选择</MenuItem>
                            {complainTypeList.map((item) => (
                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    {/* <FormControl variant="outlined" fullWidth >
                        <InputLabel >投诉类别</InputLabel>
                        <Select label="投诉类别"
                            value={complainOptId}
                            onChange={(event, value) => {
                                setComplainOptId(event.target.value);
                            }}>
                            <MenuItem value={0} disabled>请选择</MenuItem>
                            {complainOptList.map((item) => (
                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                </Grid>
                <Grid item xs={3}>
                    <Grid container alignItems="center" justifyContent="space-around">
                        <IconButton color="primary" aria-label="add" onClick={() => {openNewModal()}} >
                            <Icon path={md["mdiPlusCircle"]} size={1.5}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">选项ID</TableCell>
                                <TableCell  align="center">投诉类型</TableCell>
                                <TableCell  align="center">投诉标题</TableCell>
                                <TableCell  align="center">投诉提示</TableCell>
                                <TableCell  align="center">操作</TableCell>
                                {/* <TableCell  align="center">操作</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(complainTypeList,row.type)}</TableCell>
                                    <TableCell align="center">{row.title}</TableCell>
                                    <TableCell align="center">{row.content}</TableCell>
                                    
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                  
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,n)=>{changePage(n)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            } */}
            <SimpleModal
                maxWidth={'sm'}
                title={ '修改投诉选项'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{updateComplianOpt()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Tabs
                    value={tabIndex}
                    onChange={(e,v)=>setTabIndex(v)}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="投诉选项" value={1} />
                    <Tab label="投诉子选项" value={2} />
                </Tabs>
                <div hidden={1 !== tabIndex} value={1} index={1} id={1}>
                    <Grid container spacing={1} marginTop={2}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >投诉类型</InputLabel>
                                <Select label="投诉类型"
                                    value={modelObj.type}
                                    disabled
                                    onChange={(event, value) => {
                                        setModelObj({...modelObj,type:e.target.value});
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {complainTypeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="投诉选项ID" disabled fullWidth margin="dense" variant="outlined" value={modelObj.id}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="投诉标题" fullWidth margin="dense" variant="outlined" value={modelObj.title}
                                onChange={(e) => {
                                    setModelObj({...modelObj,title:e.target.value});
                                }}
                                error={validation.title&&validation.title!=''}
                                helperText={validation.title}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="投诉提示" fullWidth margin="dense" variant="outlined" multiline rows={4} value={modelObj.content}
                                onChange={(e) => {
                                    setModelObj({...modelObj,content:e.target.value});
                                }}
                                error={validation.content&&validation.content!=''}
                                helperText={validation.content}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div hidden={2 !== tabIndex} value={2} index={2} id={2}>
                    {complainOptSubList.map((item,index)=> 
                        <Grid container spacing={1} marginTop={2} alignItems="center" key={"complain_opt_sub_"+index}>
                            <Grid item xs={3}>
                                <TextField label="投诉子选项ID" disabled fullWidth margin="dense" variant="outlined" value={item.id}/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField label="投诉子选项说明" fullWidth margin="dense" variant="outlined" value={item.name}/>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton color="primary" aria-label="add" onClick={() => {}} >
                                    <Icon path={md["mdiCommentEditOutline"]} size={1.5}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1} marginTop={2} alignItems="center">
                        <Grid item xs={3}>
                            <TextField label="投诉子选项ID" fullWidth margin="dense" variant="outlined" value={subModelObj.id} 
                                onChange={(e) => {setSubModelObj({...subModelObj,id:e.target.value});}}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField label="投诉子选项说明" fullWidth margin="dense" variant="outlined" value={subModelObj.name}
                                onChange={(e) => {setSubModelObj({...subModelObj,name:e.target.value});}}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton color="primary" aria-label="add" onClick={() => {createComplianOptSub()}} >
                                <Icon path={md["mdiPlusBox"]} size={1.5}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>

            </SimpleModal>

            <SimpleModal
                maxWidth={'sm'}
                title={ '新增投诉选项'}
                open={newModalOpen}
                onClose={closeNewModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{createComplianOpt()}}>确定</Button>
                        <Button  onClick={closeNewModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel >投诉类型</InputLabel>
                            <Select label="投诉类型"
                                value={modelObj.type}
                                onChange={(e, value) => {
                                    setModelObj({...modelObj,type:e.target.value});
                                }}>
                                <MenuItem value={0} disabled>请选择</MenuItem>
                                {complainTypeList.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="投诉选项ID" fullWidth margin="dense" variant="outlined" value={modelObj.id}
                            onChange={(e) => {
                                setModelObj({...modelObj,id:e.target.value});
                            }}
                            error={validation.id&&validation.id!=''}
                            helperText={validation.id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="投诉标题" fullWidth margin="dense" variant="outlined" value={modelObj.title}
                            onChange={(e) => {
                                setModelObj({...modelObj,title:e.target.value});
                            }}
                            error={validation.title&&validation.title!=''}
                            helperText={validation.title}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="投诉提示" fullWidth margin="dense" variant="outlined" multiline rows={4} value={modelObj.content}
                            onChange={(e) => {
                                setModelObj({...modelObj,content:e.target.value});
                            }}
                            error={validation.content&&validation.content!=''}
                            helperText={validation.content}
                        />
                    </Grid>
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplainOptSetting);
import React, {useEffect,useMemo,useState} from 'react';
import {connect} from 'react-redux';

import { Avatar,Box,Button, Divider,FormControl,Grid,IconButton,ImageList,ImageListItem,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import Viewer from 'viewerjs'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncComplainOpts, getAsyncComplainOptSub, getAsyncProfileComplainList, updateAsyncProfileComplain } from '../../store/actions/main/ComplainAction';
import { complainStatusList } from '../../utils/SysData';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';
import SimpleModal from '../layout/SimpleModal';
import { fileHost } from '../../config';

const commonUtil = require("../../utils/CommonUtil")

const ComplainProfile = (props) => {
    
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const [complainOptList, setComplainOptList] = useState([]);
    const [complainOptSubList, setComplainOptSubList] = useState([]);
    const [bizName, setBizName] = useState("")
    const [bizShortName, setBizShortName] = useState("")
    const [posTitle, setPosTitle] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [bizUserName, setBizUserName] = useState("")
    const [bizUserPhone, setBizUserPhone] = useState("")
    const [status, setStatus] = useState("")
    const [complainOptId, setComplainOptId] = useState("")
    const [complainOptSubId, setComplainOptSubId] = useState("")
    const [createdStart, setCreatedStart] = useState()
    const [createdEnd, setCreatedEnd] = useState()

    const [modelObj, setModelObj] = useState({})
    const [modelArray, setModelArray] = useState([])
    const [modalOpen, setModalOpen] = useState(false);  

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = (data)=>{
        setModalOpen(true)
        setModelObj(data)
    }


    const showImageList = ( )=>{
        const gallery = new Viewer(document.getElementById('live_complian_img_list'));
       
    }

    const query = async(pageNumber)=>{
        const queryObj ={
            status:3,
            pageNumber,pageSize,bizName,name,phone,bizUserName,bizUserPhone,status,complainOptId,complainOptSubId
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncProfileComplainList(queryObj);
        if(res && res.list){
            console.log(res)
            setModelArray([...res.list])
            setTotal(res.total)
        }
    }
    const reset = ()=>{
        setBizName("");
        setName("");
        setPhone("");
        setBizUserName("");
        setBizUserPhone("");
        setStatus("");
        setComplainOptId();
        setComplainOptSubId();
        setCreatedStart(null);
        setCreatedEnd(null);
    }

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }

    const queryProfileComplainOpt = async () => {
        const res = await getAsyncComplainOpts({type:2})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].title,value:res.list[i].id})
            }
            setComplainOptList([...tmpArray])
        }
    }
    const queryProfileComplainSubOpt = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncComplainOptSub(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setComplainOptSubList([...tmpArray])
            }
        }
    }

    const save = (status) => {        
        setModalOpen(false);
        
        updateModel(status)
    }

    const updateModel = async(status)=>{
        const res = await updateAsyncProfileComplain({...modelObj,status})
        changePage(pageNumber)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }

    useEffect(()=>{
        query(pageNumber);
        queryProfileComplainOpt()
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >简历投诉</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                        
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="被投诉人姓名"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                         <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="被投诉人人电话"
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="投诉人姓名"
                                value={bizUserName}
                                onChange={(e)=>setBizUserName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                         <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="投诉人人电话"
                                value={bizUserPhone}
                                onChange={(e)=>setBizUserPhone(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                    <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >处理状态</InputLabel>
                                <Select label="处理状态"
                                    value={status}
                                    onChange={(event) => {
                                        setStatus(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {complainStatusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >投诉类别</InputLabel>
                                <Select label="投诉类别"
                                    value={complainOptId}
                                    onChange={(event, value) => {
                                        setComplainOptId(event.target.value);
                                        queryProfileComplainSubOpt({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {complainOptList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >投诉子类</InputLabel>
                                <Select label="投诉子类"
                                    value={complainOptSubId}
                                    onChange={(event, value) => {
                                        setComplainOptSubId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {complainOptSubList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">企业ID</TableCell>
                                <TableCell  align="center">企业简称</TableCell>
                                <TableCell  align="center">投诉人</TableCell>
                                <TableCell  align="center">被投诉人</TableCell>
                                <TableCell  align="center">投诉分类</TableCell>
                                <TableCell  align="center">投诉子类</TableCell>
                                <TableCell  align="center">处理状态</TableCell>
                                <TableCell  align="center">投诉时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                                {/* <TableCell  align="center">操作</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modelArray.length > 0 && modelArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.bizId}</TableCell>
                                    <TableCell align="center">{row.bizShortName}</TableCell>
                                    <TableCell align="center">{row.bizUserName}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.complainOptTitle}</TableCell>
                                    <TableCell align="center">{row.complainOptSubName}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(complainStatusList, row.status)}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                  
                                </TableRow>))}
                                { modelArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,n)=>{changePage(n)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'sm'}
                title={ '投诉详情'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    <Paper sx={{padding:2,marginTop:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">被投诉人</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{padding:1}}>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item>
                                    <Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">姓名:  </Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.name}</Typography> 
                                        </Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">电话:  </Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.phone}</Typography> 
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                {modelObj.avatar && modelObj.avatar !='' && 
                                    <Avatar src={"http://"+fileHost+ modelObj.avatar} sx={{ width: 48, height: 48}}   />}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{padding:2,width:"100%",marginTop:2}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">投诉信息</Typography>
                            </Grid>
                        </Grid>
                        <Grid container  spacing={1} style={{paddingTop:10}}>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">投诉类别:  </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.complainOptTitle}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">投诉子类:  </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.complainOptSubName}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                    <Typography gutterBottom variant="button" component="div">投诉内容:  </Typography>
                                    <Typography gutterBottom variant="overline" component="div">{modelObj.complainDesc}</Typography> 
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1} >
                                    <Typography gutterBottom variant="button" component="div">投诉图片:  </Typography>
                                    {modelObj.complainUrl && modelObj.complainUrl.length>0 && <ImageList id="live_complian_img_list" sx={{ width: "100%" }} variant="quilted" cols={6} rowHeight={164} >
                                        
                                        {modelObj.complainUrl.map((item,index) => (
                                            <ImageListItem key={"_live_complain_img_list_"+index} onClick={showImageList}>
                                                <img
                                                    style={{objectFit:"cover"}}
                                                    src={`http://${fileHost}${item}`}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={{padding:2,width:"100%",marginTop:2}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">投诉企业信息</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{padding:1}}>
                            <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={12}>
                                    <Stack direction="row" sx={{alignItems: 'center', justifyContent:"space-between"}}>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">企业ID:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizId}</Typography> 
                                        </Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">企业简称:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizShortName}</Typography> 
                                        </Stack>
                                    </Stack>

                                    <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                        <Typography gutterBottom variant="button" component="div">企业名称:</Typography>
                                        <Typography gutterBottom variant="overline" component="div">{modelObj.bizName}</Typography> 
                                    </Stack>
                                    <Stack direction="row" sx={{alignItems: 'center', justifyContent:"space-between"}}>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">投诉人姓名:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizUserName}</Typography> 
                                        </Stack>
                                        <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                            <Typography gutterBottom variant="button" component="div">投诉人电话:</Typography>
                                            <Typography gutterBottom variant="overline" component="div">{modelObj.bizUserPhone}</Typography> 
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                {modelObj.bizAvatar && modelObj.bizAvatar !='' && 
                                    <Avatar src={"http://"+fileHost+ modelObj.bizAvatar} variant='square' sx={{ width: 96, height: 96}}   />}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{padding:2,marginTop:2,width:"100%"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6" component="div" align="center">处理投诉</Typography>
                            </Grid>
                        </Grid>
                        { modelObj.status != 1 &&
                        <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}></Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                        <Typography gutterBottom variant="button" component="div">{commonUtil.getComplaintatus(modelObj.status)}:  </Typography>
                                        <Typography gutterBottom variant="overline" component="div">{modelObj.reply}</Typography> 
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                        <Typography gutterBottom variant="button" component="div">备注:  </Typography>
                                        <Typography gutterBottom variant="overline" component="div">{modelObj.remark}</Typography> 
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}  sx={{alignItems: 'center' }}>
                                        <Typography gutterBottom variant="button" component="div">处理时间:  </Typography>
                                        <Typography gutterBottom variant="overline" component="div">{new Date(modelObj.UpdatedAt).toLocaleString()}</Typography> 
                                    </Stack>
                                </Grid>
                            </Grid>
                            
                        </Grid>}
                        {modelObj.status == 1 && <Grid container alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <TextField id="input-with-sx" value={modelObj.reply} onChange={(e)=>setModelObj({...modelObj,reply:e.target.value})} label="投诉回复" style={{width:"100%"}} variant="outlined" />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box marginTop={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <TextField id="input-with-sx" placeholder='仅操作人员可见' value={modelObj.remark} onChange={(e)=>setModelObj({...modelObj,remark:e.target.value})} label="备注信息" style={{width:"90%"}} variant="outlined" />
                                    <Tooltip title="处理回复">
                                        <IconButton  onClick={()=>{save(2)}}>
                                            <Icon path={md["mdiCheckCircle"]} style={{paddingLeft:10,color:"green"}} size={1.5}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>}
                    </Paper>
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplainProfile);
import React ,{useState,useEffect}from 'react';
import { connect } from 'react-redux';
import screenfull from "screenfull";
import { useConfirm } from "material-ui-confirm";
import {AppBar,Avatar,Badge,Box,Divider,IconButton,ListItemIcon,ListItemText,Menu,MenuItem,Toolbar,Typography} from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LightModeIcon from '@mui/icons-material/LightMode';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LockIcon from '@mui/icons-material/Lock';
import BadgeIcon from '@mui/icons-material/Badge';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.scss';
import { toggleTheme,adminLogout, getCurrentAdmin } from '../../store/actions/layout/AppAction'
import UpdatePasswordModal from './UpdatePasswordModal';
import EditAvatarModal from './EditAvatarModal';


const Header = (props) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [accountModalOpenFlag, setAccountModalOpenFlag] = React.useState(false);
    const [editModalOpenFlag, setEditModalOpenFlag] = React.useState(false);

    const isMenuOpen = Boolean(anchorEl);
    const { appReducer, toggleTheme,adminLogout ,getCurrentAdmin } = props;
    const confirm = useConfirm();
    const toggleFullScreen = () => {
        screenfull.toggle();
        setIsFullscreen(!isFullscreen);
        
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const openAccountModal = (event) => {
        handleMenuClose();
        setAccountModalOpenFlag(true);
    };
    const closeAccountModal = (event) => {
        setAccountModalOpenFlag(false);
    };

    const openEditAvatarModal = (event) => {
        handleMenuClose();
        setEditModalOpenFlag(true);
    };
    const closeEditAvatarModal = (event) => {
        setEditModalOpenFlag(false);
    };

    const logout =()=>{
        handleMenuClose();        
        confirm({ title:"退出登录",description: "确定要退出系统?",confirmationText:"确定",cancellationText:"取消"})
        .then(() =>{
            adminLogout();
        }).catch(() => {
            console.log("取消推出!");
        });
    }
    useEffect(()=>{
        console.log("get current admin")
        getCurrentAdmin();
    },[])
    const renderMenu = (
        <Menu
            sx={{ mt: '32px' }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            >  
        <MenuItem onClick={openEditAvatarModal}>
            <ListItemIcon>
                <BadgeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{appReducer.currentUser.userName}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={openAccountModal}>
            <ListItemIcon>
                <LockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>修改密码</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={logout}>
          <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>退出登录</ListItemText>
          </MenuItem>
        </Menu>
      );
    const handleAccountMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    return (
        <Box>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar variant="dense">
            <img  width="64" height="64" src="/logo_reverse.png" ></img>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' },ml:1 }}
            >
                直播招聘管理后台
            </Typography>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 2 }}
            >
                <MenuIcon />
            </IconButton>
            
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                >
                {/* <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                </Badge> */}
                </IconButton>
                <IconButton  color="inherit" onClick={() => { toggleTheme() }}>
                    {appReducer.darkMode=='light'?<DarkModeIcon  />:<LightModeIcon/>}
                </IconButton>
                <IconButton  color="inherit" onClick={()=>{toggleFullScreen()}}>
                    {isFullscreen?<FullscreenExitIcon />:<FullscreenIcon/>}
                </IconButton>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    onClick={handleAccountMenuOpen}
                    aria-haspopup="true"
                    color="inherit"
                    >
                    <AccountCircle />
                    {/* <Avatar>A</Avatar> */}
                </IconButton>
            </Box>
            </Toolbar>
        </AppBar>
        {renderMenu}
        <UpdatePasswordModal openFlag={accountModalOpenFlag} closeAccountModal={closeAccountModal}/>
        <EditAvatarModal openFlag={editModalOpenFlag} closeEditModal={closeEditAvatarModal}/>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        appReducer: state.AppReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    toggleTheme: () => { dispatch(toggleTheme()) },
    adminLogout:()=>{dispatch(adminLogout())},
    getCurrentAdmin:()=>{dispatch(getCurrentAdmin())},
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);